import { HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { OAuthModule, OAuthService, OAuthStorage } from 'angular-oauth2-oidc';
import { BearinxClipboardService } from 'src/modules/clipboard/bearinx-clipboard.service';
import { AuthModule } from '../../modules/auth/auth.module';
import { AuthenticationService } from '../../modules/auth/authentication.service';
import { DataModelModule } from '../../modules/data-model/data-model.module';
import { ErrorHandlingModule } from '../../modules/error-handling/error-handling.module';
import { GlobalHotkeyService } from '../../modules/hotkey/global-hotkey.service';
import { LoadingIndicatorModule } from '../../modules/loading-indicator/loading-indicator.module';
import { LockingModule } from '../../modules/locking/locking.module';
import { ModelModule } from '../../modules/model/model.module';
import { SettingsModule } from '../../modules/settings/settings.module';
import { SettingsService } from '../../modules/settings/settings.service';
import { SpinnerModule } from '../../modules/spinner/spinner.module';
import { ToastModule } from '../../modules/toast/toast.module';
import { AppContainerModule } from './app-container/app-container.module';
import { AppComponent } from './app.component';
import { BearinxRoutingModule } from './bearinx.routing';
import { loadAppConfig } from 'src/modules/util/util';
import { ThumbnailModule } from 'src/modules/thumbnail/thumbnail.module';
import { OneTrustService } from 'src/modules/one-trust-cookie/one-trust.service';

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, 'assets/i18n/');
}

function loadUrls() {
    const allowedUrls = loadAppConfig();
    return [allowedUrls.baseUrl.toLocaleLowerCase(), allowedUrls.settingsUrl.toLocaleLowerCase()];
}

export function loadOneTrustId() {
    const oneTrustUrl = loadAppConfig();
    return oneTrustUrl.oneTrustId;
}

export function appInitializer(oneTrustService: OneTrustService, translateService: TranslateService) {
    const windowAny = window as any;
    if (windowAny.Cypress != null) {
        console.log('No-TrustService initialized');
        return () => null;
    } else {
        return () => oneTrustService.loadOneTrust(loadOneTrustId(), translateService.currentLang);
    }
}

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        BearinxRoutingModule,
        AuthModule.forRoot(),
        DataModelModule.forRoot(),
        ErrorHandlingModule.forRoot(),
        ThumbnailModule,
        OAuthModule.forRoot({
            resourceServer: {
                allowedUrls: loadUrls(), // <== we need to set the value that we loaded from the external endpoint (JSON) here
                sendAccessToken: true,
            },
        }),
        SpinnerModule.forRoot(),
        ModelModule.forRoot(),
        SettingsModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),

        LockingModule.forRoot(),
        AppContainerModule,
        ToastModule,
        LoadingIndicatorModule,
    ],
    declarations: [AppComponent],
    bootstrap: [AppComponent],
    providers: [
        // OneTrust Provider must be first entry
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializer,
            deps: [OneTrustService, TranslateService],
            multi: true,
        },
        // Edge problem: https://github.com/manfredsteyer/angular-oauth2-oidc/issues/255
        { provide: OAuthStorage, useValue: localStorage },
    ],
})
export class BearinxModule {
    constructor(
        oauth: OAuthService,
        auth: AuthenticationService,
        globalHotkeyService: GlobalHotkeyService,
        settingsService: SettingsService,
        translateService: TranslateService,
        clipboardService: BearinxClipboardService,
    ) {
        const oAuth = loadAppConfig();
        oauth.configure(oAuth.oauth);
        auth.start();

        globalHotkeyService.initialize();
        translateService.addLangs(settingsService.languageIds);
        translateService.setDefaultLang(settingsService.defaultLanguageId);

        settingsService.currentLanguage$.subscribe(language => translateService.use(language));

        clipboardService.permissionTest();
    }
}
