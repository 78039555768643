import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { AuthenticationService } from '../auth/authentication.service';
import { WebStorageService } from '../storage/web-storage.service';
import { STRING_BEARINX_ONLINE, Tenant } from './tenant.model';

@Injectable({
    providedIn: 'root',
})
export class TenantService implements OnDestroy {
    private static readonly _CURRENT_TENANT_KEY = 'TENANT_CURRENT';

    private readonly _tenants = new BehaviorSubject<Tenant[]>([]);
    private readonly _tenant = new BehaviorSubject<Tenant | undefined>(undefined);

    private readonly _destroy$ = new Subject<void>();

    public readonly tenants$ = this._tenants.asObservable();
    public readonly tenant$ = this._tenant.asObservable();

    constructor(private readonly _webStorageService: WebStorageService, private readonly _authService: AuthenticationService) {
        this._authService.user$
            .pipe(
                map(user => user.tenants || []),
                map(tenants => tenants.filter(tenant => tenant.client === STRING_BEARINX_ONLINE)),
                tap(this._tenants),
                takeUntil(this._destroy$),
            )
            .subscribe(tenants => this._applyCurrentTenant(tenants));
    }

    public ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    public get tenants(): Tenant[] {
        return this._tenants.value;
    }

    public get tenant(): Tenant | undefined {
        return this._tenant.value;
    }

    public getTenant(tenantId: string): Tenant | undefined {
        return this.tenants.find(({ id }) => tenantId === id);
    }

    public setTenant(tenant: Tenant | undefined): void {
        if (!tenant || !this.getTenant(tenant.id)) {
            this._clearTenant();
            return;
        }
        this._tenant.next(tenant);
        this._webStorageService.setValue(TenantService._CURRENT_TENANT_KEY, tenant.id);
    }

    private _clearTenant(): void {
        this._tenant.next(undefined);
        this._webStorageService.removeValue(TenantService._CURRENT_TENANT_KEY);
    }

    private _applyCurrentTenant(tenants: Tenant[]) {
        const defaultTenant = tenants.length === 1 ? tenants[0] : undefined;
        const tenantId = this._webStorageService.getValue(TenantService._CURRENT_TENANT_KEY);

        const tenant = tenants.find(({ id }) => id === tenantId) || defaultTenant;
        this.setTenant(tenant);
    }
}
